@import '~antd/dist/antd.css';

.record-entry-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow-y: auto;
}

.left-container {
    width: 16%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
}

.hidden-left-container {
    width: 16%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    display: none;
}

.right-container {
    width: 84%;
    margin-left: 16%;
    padding: 10px;
}

.full-right-container {
    width: 100%;
    margin-left: 0%;
    padding: 10px;
}

.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    margin-top: 10px;
    background-color: aliceblue;
}

.template-tip {
    margin-bottom: 17px;
    padding: 0px 10px;
    white-space: normal;
    text-align: center;
}

.download-container {
    display: flex;
    flex-direction: row;
    color: #1890ff;
    cursor: pointer;
}

.record-table-card .ant-card-body {
    padding-bottom: 4px;
}