@import '~antd/dist/antd.css';

.trace-report-container {
    padding: 20px;
}

.trace-report-container .ant-picker-range {
    width: 100%;
}

.table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.record-title {
    font-weight: bold;
}
