@import '~antd/dist/antd.css';

.fr-generator-container .left-layout {
    width: 10rem !important;
}
  
.fr-generator-container .right-layout {
    width: 12rem !important;
}

.generator-item-container {
    padding: 10px;
    height: 60vh;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}