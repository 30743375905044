.action-button {
    width: 90px;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.justify-center {
    -webkit-box-pack: center;
    justify-content: center;
  }

.items-center {
    -webkit-box-align: center;
    align-items: center;
}

::-webkit-scrollbar {
    background-color: #E8EDF2;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #D8D8D8;
}

.ant-table-tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
}
